* {
  margin: 0px;
  padding: 0px;
}
.content-body {
  display: flex;
  justify-content: center;
  background: #f4f4f4;
  width: 100%;
  min-height: calc(100vh);
  height: fit-content;
}

.edit-profile-card {
  height: fit-content;
  min-height: calc(90vh);
  margin-top: 12px;
  border-radius: 5.13679px;
  width: 65%;
  background-color: white;
  padding: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.change_profile {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0px;
}
.change-img-icon {
  position: absolute;
  bottom: 0%;
  right: 5%;
  width: 30px;
  height: 30px;
}

.edit-information {
  width: 55%;
}
@media (max-width:600px) {
  .edit-information {
    width: 75%;
  }  
}
.btn {
  box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
 }
  
label {
  z-index: unset !important;
}
