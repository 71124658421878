.verify-btn {
  margin-top: 20px;
  width: 100%;
  min-width: 320px;
  background: #7a03fe;
  font-size: 18px;
  text-align: center;
  color: white;
  font-weight: 700;
  padding: 10px 0;
  text-transform: uppercase;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  border-radius: 15px;
  cursor: pointer;
}

.verify-btn.disabled {
  background-color: darkgray;
}
