* {
  margin: 0px;
  padding: 0px;
}
.body {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 769px) {
  .body {
    flex-direction: column;
  }
  .welome-img {
    width: 100% !important;
  }
  .reg-card {
    width: 95% !important;
    margin: 20px 2%;
  }
}

.reg-card {
  width: 50%;
  background-color: white;
  z-index: 1;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
}

.welcome-navbar {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  color: white;
  background-color: #7a03fe;
}
.welome-img {
  width: 55%;
  position: absolute;
}

.tab {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
  height: 80px;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: transparent;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0px 10px;
  transition: 0.3s;
  font-size: 17px;
  height: 40px;
}

.tab-title {
  font-style: normal;
  font-size: 30px;
  line-height: 45px;
  color: #888888;
  font-weight: 700;
  color: #7a03fe;
}

/* Create an active/current tablink class */
.tab button.active {
  color: #7a03fe;
  border-bottom: 2px solid #7a03fe;
}

/* Style the tab content */
.tabcontent {
  width: 100%;
  height: 85%;
  justify-content: center;
}

#floatingInput {
  width: 90%;
  padding: 15px 15px;
  margin: 15px 0px;
  border-radius: 10px;
  border: 1px solid gray;
}
.form-floating {
  width: 50%;
}
.reg {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0px;
}

/* search */

.patient-user-search {
  display: flex;
  justify-content: center;
  position: relative;
  width: 50%;
}
.patient-user-search input {
  border: 1px solid #cdcdcd;
  font-size: 18px;
  width: 100%;
  font-weight: 500;
  border-radius: 26px;
  background: white;
  padding: 15px 5px 15px 38px;
  color: #cdcdcd;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
}

.pateint-search-icon {
  position: absolute;
  background-color: white;
  font-size: 10px;
  border: none;
  cursor: pointer;
  left: 8px;
  margin-top: 12px;
}

.commonbtn {
  color: white;
  width: 100%;
  padding: 16px;
  margin: 20px 0px;
  background-color: #983aff;
  border: 0px;
  font-weight: 800;
  font-size: 16px;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tab-divider {
  color: #888888;
  font-size: 25px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
