@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900&display=swap');

* {
  font-family: 'Cairo', sans-serif !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* header menu hover */
.css-1q39md6-MuiButtonBase-root-MuiButton-root:hover {
  font-weight: bolder !important ;
}

.pointer {
  cursor: pointer;
}

.relative {
  position: relative;
}

.dropbtn {
  background-color: #405672;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
}

* {
  font-family: 'Cairo', sans-serif !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* Dropdown button on hover & focus */
.dropbtn:hover,
.dropbtn:focus {
  background-color: #405672;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  /* display: inline-block; */
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  position: absolute;
  background-color: #f1f1f1;
  /* min-width: 160px; */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
  display: block;
}

.tree ul {
  padding-top: 20px;
  position: relative;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  display: flex;
}

.tree li {
  float: left;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 20px 5px 0 5px;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

/*We will use ::before and ::after to draw the connectors*/

.tree li::before,
.tree li::after {
  content: '';
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 1px solid #ccc;
  width: 50%;
  height: 20px;
}
.tree li::after {
  right: auto;
  left: 50%;
  border-left: 1px solid #ccc;
}

/*We need to remove left-right connectors from elements without
any siblings*/
.tree li:only-child::after,
.tree li:only-child::before {
  display: none;
}

/*Remove space from the top of single children*/
.tree li:only-child {
  padding-top: 0;
}

/*Remove left connector from first child and
right connector from last child*/
.tree li:first-child::before,
.tree li:last-child::after {
  border: 0 none;
}
/*Adding back the vertical connector to the last nodes*/
.tree li:last-child::before {
  border-right: 1px solid #ccc;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}
.tree li:first-child::after {
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}

/*Time to add downward connectors from parents*/
.tree ul ul::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 1px solid #ccc;
  width: 0;
  height: 20px;
}

.tree li a {
  border: 1px solid #ccc;
  padding: 5px 10px;
  text-decoration: none;
  color: #666;
  font-family: arial, verdana, tahoma;
  font-size: 11px;
  display: inline-block;

  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

/*Time for some hover effects*/
/*We will apply the hover effect the the lineage of the element also*/
.tree li a:hover,
.tree li a:hover + ul li a {
  background: #c8e4f8;
  color: #000;
  border: 1px solid #94a0b4;
}
/*Connector styles on hover*/
.tree li a:hover + ul li::after,
.tree li a:hover + ul li::before,
.tree li a:hover + ul::before,
.tree li a:hover + ul ul::before {
  border-color: #94a0b4;
}

.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.file-input__label svg {
  height: 50px;
  margin-right: 50px;
}

body {
  margin: 0;
  padding: 0;
}

.rightMargin::before {
  left: 53% !important;
}

.rightMargin {
  margin-right: 50px;
}

/* circular progress Css */

.cardDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff;
  margin: 0 20px;
  width: 280px;
  height: 350px;
  border-radius: 20px;
}

.cardDiv .percent {
  position: relative;
}

.cardDiv svg {
  position: relative;
  width: 210px;
  height: 210px;
  transform: rotate(-90deg);
}

.cardDiv svg circle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: #f0f0f0;
  stroke-width: 10;
  stroke-linecap: round;
}

.cardDiv svg circle:last-of-type {
  stroke-dasharray: 625px;
  stroke-dashoffset: calc(625px - (625px * var(--percent)) / 100);
  stroke: #7a03fe;
}

.cardDiv .number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cardDiv .number h3 {
  font-weight: 200;
  font-size: 3.5rem;
}

.cardDiv .number h3 span {
  font-size: 2rem;
}

.cardDiv .title h2 {
  margin: 25px 0 0;
}

.modalBG {
  background-color: #00000040;
}

.introjs-tooltipbuttons a {
  background-color: #7a03fe;
  color: white;
  border-radius: 20px;
}

.introjs-tooltipbuttons a:hover,
.introjs-tooltipbuttons a:active {
  background-color: #7a03fe;
  color: white;
  border-radius: 20px;
}

.myTooltipClass2 {
  position: fixed !important;
  top: 20%;
  left: 0;
  right: 0;
}

/* Edit Profile */

.muibox-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -35px;
}

.container-profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 420px;
  min-width: 320px;
  width: 100%;
  margin: 20px auto;
  overflow-x: hidden;
  margin-bottom: 40px;
}

span.update-profile-btn {
  background-color: #7a03fe;
  margin-top: 2;
  width: 100%;
  margin: 20px 0;
  border-radius: 15px;
  padding: 15px 0;
}

span.update-profile-btn:hover {
  background-color: #7a03fe;
}

/* body part list show */

.container-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-self: center;
}

.container-coloumn {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 7px;
}

.display-image {
  width: 150px;
  height: 150px;
  object-fit: contain;
  cursor: pointer;
}

span.body-part-list-btn {
  background: white;
  width: 150px;
  height: 50px;
  font-size: 20px;
  border-radius: 15px;
}

span.body-part-list-btn:hover {
  background: #7a03fe;
  color: white;
}

/* Question list */

.container-root-department {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 45%));
  grid-gap: 30px;
  justify-content: center;
  margin-bottom: 50px;
}

.container-box-department {
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
}

/* subheader */

.sub-container {
  display: flex;
  flex-direction: column;
  background-color: #7a03fe;
  margin-top: -28px;
  padding-top: 30px;
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
  margin-bottom: 50px;
  padding: 20px;
  margin-left: -10%;
  margin-right: -10%;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 30px;
  align-items: center;
  width: 100%;
}

.header-button {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  width: 100%;
}

.sub-heading {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
}

.sub-title {
  text-align: center;
  margin: auto;
  color: white;
}

/* 'react-responsive-carousel/lib/styles/carousel.min.css' */

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  -webkit-transition: all 0.25s ease-in;
  -moz-transition: all 0.25s ease-in;
  -ms-transition: all 0.25s ease-in;
  -o-transition: all 0.25s ease-in;
  transition: all 0.25s ease-in;
  opacity: 0.4;
  filter: alpha(opacity=40);
  position: absolute;
  z-index: 2;
  top: 20px;
  background: none;
  border: 0;
  font-size: 32px;
  cursor: pointer;
}
.carousel .control-arrow:focus,
.carousel .control-arrow:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  margin: 0 5px;
  display: inline-block;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  content: '';
}
.carousel .control-disabled.control-arrow {
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: inherit;
  display: none;
}
.carousel .control-prev.control-arrow {
  left: 0;
}
.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #fff;
}
.carousel .control-next.control-arrow {
  right: 0;
}
.carousel .control-next.control-arrow:before {
  border-left: 8px solid #fff;
}
.carousel-root {
  outline: none;
}
.carousel {
  position: relative;
  width: 100%;
}
.carousel * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.carousel img {
  width: 100%;
  display: inline-block;
  pointer-events: none;
}
.carousel .carousel {
  position: relative;
}
.carousel .control-arrow {
  outline: 0;
  border: 0;
  background: none;
  top: 50%;
  margin-top: -13px;
  font-size: 18px;
}
.carousel .thumbs-wrapper {
  margin: 20px;
  overflow: hidden;
}
.carousel .thumbs {
  -webkit-transition: all 0.15s ease-in;
  -moz-transition: all 0.15s ease-in;
  -ms-transition: all 0.15s ease-in;
  -o-transition: all 0.15s ease-in;
  transition: all 0.15s ease-in;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  position: relative;
  list-style: none;
  white-space: nowrap;
}
.carousel .thumb {
  -webkit-transition: border 0.15s ease-in;
  -moz-transition: border 0.15s ease-in;
  -ms-transition: border 0.15s ease-in;
  -o-transition: border 0.15s ease-in;
  transition: border 0.15s ease-in;
  display: inline-block;
  margin-right: 6px;
  white-space: nowrap;
  overflow: hidden;
  border: 3px solid #fff;
  padding: 2px;
}
.carousel .thumb:focus {
  border: 3px solid #ccc;
  outline: none;
}
.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 3px solid #333;
}
.carousel .thumb img {
  vertical-align: top;
}
.carousel.carousel-slider {
  position: relative;
  margin: 0;
  overflow: hidden;
}
.carousel.carousel-slider .control-arrow {
  top: 0;
  color: #fff;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 5px;
}
.carousel.carousel-slider .control-arrow:hover {
  background: rgba(0, 0, 0, 0.2);
}
.carousel .slider-wrapper {
  overflow: hidden;
  margin: auto;
  width: 100%;
  -webkit-transition: height 0.15s ease-in;
  -moz-transition: height 0.15s ease-in;
  -ms-transition: height 0.15s ease-in;
  -o-transition: height 0.15s ease-in;
  transition: height 0.15s ease-in;
}
.carousel .slider-wrapper.axis-horizontal .slider {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}
.carousel .slider-wrapper.axis-horizontal .slider .slide {
  flex-direction: column;
  flex-flow: column;
}
.carousel .slider-wrapper.axis-vertical {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}
.carousel .slider-wrapper.axis-vertical .slider {
  -webkit-flex-direction: column;
  flex-direction: column;
}
.carousel .slider {
  margin: 0;
  padding: 0;
  position: relative;
  list-style: none;
  width: 100%;
}
.carousel .slider.animated {
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.carousel .slide {
  min-width: 100%;
  margin: 0;
  position: relative;
  text-align: center;
}
.carousel .slide img {
  width: 100%;
  vertical-align: top;
  border: 0;
}
.carousel .slide iframe {
  display: inline-block;
  width: calc(100% - 80px);
  margin: 0 40px 40px;
  border: 0;
}
.carousel .slide .legend {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: absolute;
  bottom: 40px;
  left: 50%;
  margin-left: -45%;
  width: 90%;
  border-radius: 10px;
  background: #000;
  color: #fff;
  padding: 10px;
  font-size: 12px;
  text-align: center;
  opacity: 0.25;
  -webkit-transition: opacity 0.35s ease-in-out;
  -moz-transition: opacity 0.35s ease-in-out;
  -ms-transition: opacity 0.35s ease-in-out;
  -o-transition: opacity 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out;
}
.carousel .control-dots {
  position: absolute;
  bottom: 0;
  margin: 10px 0;
  padding: 0;
  text-align: center;
  width: 100%;
  z-index: 1;
}
@media (min-width: 960px) {
  .carousel .control-dots {
    bottom: 0;
  }
}
.carousel .control-dots .dot {
  -webkit-transition: opacity 0.25s ease-in;
  -moz-transition: opacity 0.25s ease-in;
  -ms-transition: opacity 0.25s ease-in;
  -o-transition: opacity 0.25s ease-in;
  transition: opacity 0.25s ease-in;
  opacity: 0.3;
  filter: alpha(opacity=30);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
}
.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.carousel .carousel-status {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  font-size: 10px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
  color: #fff;
}
.carousel:hover .slide .legend {
  opacity: 1;
}

/* doctorHome css */

* {
  margin: 0px;
  padding: 0px;
}
a {
  text-decoration: none;
  color: white;
}

.view-more {
  text-decoration: none;
  color: white;
}

.content-body {
  position: relative;
  display: flex;
  justify-content: center;
  background: #f4f4f4;
  width: 100%;
  min-height: calc(100vh);
  height: fit-content;
}
.patient-card-list {
  min-height: calc(100vh);
  height: fit-content;
  margin-top: 110px;
  width: 80%;
  background-color: white;
  padding: 60px 60px 20px 60px;
}
/* On mouse-over, add a deeper shadow */
.patient-card-list:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.user-search {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 55%;
  top: 65px;
}
@media (max-width: 600px) {
  .user-search {
    width: 75%;
    top: 85px;
  }
  .patient-card-list {
    width: 90%;
    padding: 60px 10px;
  }
}
.user-search input {
  width: 100%;
  border: 0px;
  font-size: 22px;
  font-weight: 500;
  border-radius: 10px;
  background: white;
  padding: 25px 50px;
  color: gray;
  padding-left: 78px !important;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
}
@media (max-width: 600px) {
  .user-search input {
    padding: 8px 45px;
    padding-left: 55px !important;
    font-size: 18px;
  }
  .search-icon {
    left: 16px !important;
    margin-top: 13px !important;
  }
}
.user-search input:focus-visible {
  outline-color: #7a03fe;
}

.search-icon {
  width: 25px;
  height: 25px;
  position: absolute;
  background-color: white;
  font-size: 12px;
  border: none;
  cursor: pointer;
  left: 30px;
  margin-top: 33px;
}

.card {
  margin: 80px 30px 40px 30px;
  height: 175px;
  width: 214px;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  position: relative;
}

.patient-img-div {
  width: 100px;
  height: 100px;
  position: absolute;
  top: -60px;
  background-color: rgb(255, 255, 255);
  border-radius: 100px;
  border: 5px solid #fff;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
}
.patient-img {
  width: 100px;
  height: 100px;
  background-color: rgb(255, 255, 255);
  border-radius: 100px;
}
.patient-img:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.reg-number {
  margin-top: 20px;
}

.d-none {
  display: none;
}
.gender-span {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #433a4e;
  opacity: 0.6;
}
.view-more-parient {
  background-color: #7a03fe;
  padding: 0.35rem 1.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  position: absolute;
  border-radius: 0.2rem;
  border: 0px;
  color: #fff;
  bottom: -10%;
  box-shadow: 0px 4px 30px rgba(148, 51, 255, 0.4);
  cursor: pointer;
}

.patient-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: space-between;
}

.nurse-patient-list {
  display: flex;
  justify-content: space-around;
  align-content: space-between;
}

.patient-name {
  margin-top: 20px;
}

/* header menu hover  */
.css-6jat1c:hover {
  text-decoration: none;
  font-weight: bold;
}
